export const WindowSize = () => {

    let viewportwidth
    let viewportheight
    if (typeof window !== "undefined") {
        if (typeof window.innerWidth != 'undefined') {
            viewportwidth = window.innerWidth
            viewportheight = window.innerHeight
        }
        else if (
            typeof document.documentElement != 'undefined' &&
            typeof document.documentElement.clientWidth != 'undefined' &&
            document.documentElement.clientWidth !== 0) {
            viewportwidth = document.documentElement.clientWidth
            viewportheight = document.documentElement.clientHeight
        }
        else {
            var body = document.getElementsByTagName('body')[0]
            viewportwidth = body.clientWidth
            viewportheight = body.clientHeight
        }


        return {
            screenWidth:window.screen.width,
            screenHeight:window.screen.height,
            viewportwidth: viewportwidth,
            viewportheight: viewportheight,


        }
    }
}