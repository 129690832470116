/*

const data = {
    name: "emre",
    surname: "Cete"
}

//console.log(StorageAction.set("deneme", data).then(item => console.log(item)));


const st = new StorageAction();
st.set("deneme",data).then(item => console.log(item))
st.get("deneme").then(item => console.log(item))


const se = new SessionAction();
se.set("deneme",data).then(item => console.log(item));
se.get("deneme").then(item => console.log(item));


const co = new CookieAction();                                                                                       
co.set("deneme",data,new Date()).then(item => console.log(item));
co.get("deneme").then(item => console.log(item));
*/


export const storageGet = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key) || "{}");
  } catch (error) {
    return localStorage.getItem(key);
  }
}

export const storageSet = (key, payload) => {
  try {
    localStorage.setItem(
      key,
      typeof payload === "object" ? JSON.stringify(payload) : payload
    );
    return {
      success: true,
      message: "The object was added to  storage successfuly"
    };
  } catch (error) {
    return {
      success: false,
      message: error
    };
  }
}

export const storageRemove = (key) => {
  try {
    localStorage.removeItem(key);
    return {
      success: true,
      message: "The object removed successfuly"
    };
  } catch (error) {
    return {
      success: false,
      message: error
    };
  }
}

export const storageClear = () => {
  try {
    localStorage.clear();
    return {
      success: true,
      message: "Storage cleared successfuly"
    };
  } catch (error) {
    return {
      success: false,
      message: error
    };
  }
}



