export const sessionGet = (key) => {
  try {
    return JSON.parse(sessionStorage.getItem(key) || "{}");
  } catch (error) {
    return (sessionStorage.getItem(key));
  }
}

export const sessionSet = (key, payload) => {
  try {
    sessionStorage.setItem(
      key,
      typeof payload === "object" ? JSON.stringify(payload) : payload
    );
    return {
      success: true,
      message: "The object was added to  session successfuly"
    };
  } catch (error) {
    return {
      success: false,
      message: error
    };
  }
}

export const sessionRemove = (key) => {
  try {
    sessionStorage.removeItem(key);
    return {
      success: true,
      message: "The object removed successfuly"
    };
  } catch (error) {
    return {
      success: false,
      message: error
    };
  }
}

export const sessionClear = () => {
  try {
    sessionStorage.clear();
    return {
      success: true,
      message: "Session cleared successfuly"
    };
  } catch (error) {
    return {
      success: false,
      message: error
    };
  }
}
