

export const cookieGet = (key) => {
  let coockies = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
  let cookie = coockies ? coockies[2] : null;
  try {
    return JSON.parse(cookie || "");
  } catch (error) {
    return (cookie);
  }
}

export const cookieSet = (key, payload, expired) => {
  try {
    let cookie =
      key +
      "=" +
      (typeof payload === "object" ? JSON.stringify(payload) : payload) +
      (expired ? ";expires=" + expired : "");
    document.cookie = cookie;
    return {
      success: true,
      message: "The object was added to cookie successfuly"
    };
  } catch (error) {
    return {
      success: false,
      message: error
    };
  }
}

export const cookieRemove = (key) => {
  try {
    document.cookie = key + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    return {
      success: true,
      message: "The object was remove from cookie successfuly"
    };
  } catch (error) {
    return {
      success: false,
      message: error
    };
  }
}

export const cookieClear = () => {
  try {
    document.cookie.split(";").forEach(c => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    return {
      success: true,
      message: "Cookies cleared successfuly"
    };
  } catch (error) {
    return {
      success: false,
      message: error
    };
  }
}

