import { osVersion, osName, fullBrowserVersion, browserName, mobileVendor, mobileModel, deviceType, getUA } from "react-device-detect";




export const ParseUserAgent= {
    osVersion: osVersion,
    osName: osName,
    fullBrowserVersion: fullBrowserVersion,
    browserName: browserName,
    mobileVendor: mobileVendor,
    mobileModel: mobileModel,
    deviceType: deviceType,
    getUA: getUA,


};